<template>
 <v-container fluid class="py-3 px-5">
   <v-row>
     <v-col cols="12" class="pb-1">
       <p class="ma-1">
         PARÁMETROS DE RESERVAS
       </p>
     </v-col>
   </v-row>
   <v-row>
     <v-col cols="12" class="pt-1">
       <ReservasParamsTable :headers="headers" :boards="boards">

       </ReservasParamsTable>
     </v-col>
   </v-row>
 </v-container>
</template>

<script>
import ReservasParamsTable from '@/components/ReservasParamsTable.vue' 
export default {
  name: 'ReservasParams',
  components: {
    ReservasParamsTable
  },
  data() {
    return {
      headers: {
        market: [
          {
            text: 'Tipo',
            align: 'start',
            value: 'type',
          },
          {
            text: 'Nombre',
            align: 'start',
            value: 'name',
          },
          {
            text: "Acciones",
            align: 'center', 
            value: "actions"
          }
        ],
        segment: [
          {
            text: 'Tipo',
            align: 'start',
            value: 'type',
          },
          {
            text: 'Nombre',
            align: 'start',
            value: 'name',
          },
          {
            text: "Acciones",
            align: 'center', 
            value: "actions"
          }          
        ],
        reservation_status: [
          {
            text: 'Code',
            align: 'start',
            value: 'code',
          },
          {
            text: 'Nombre',
            align: 'start',
            value: 'name',
          },
          {
            text: "Acciones",
            align: 'center', 
            value: "actions"
          }          
        ],
        payment_method: [
          {
            text: 'Nombre',
            align: 'start',
            value: 'name',
          },
          {
            text: 'Code',
            align: 'start',
            value: 'code',
          },
          {
            text: "Acciones",
            align: 'center', 
            value: "actions"
          }          
        ]
      },
      boards: {
        market: 'market',
        segment: 'segment',
        reservation_status: 'reservation_status',
        payment_method: 'payment_method'
      }
    }
  },
}
</script>

<style>

</style>