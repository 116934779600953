<template>
  <div>
    <v-row align="start">
      <v-col sm="12" md="6">
        <v-card class="rounded-xl">
          <v-card-title class="py-2">
            <v-row align="center" justify="space-around">
              <v-col xs="12" sm="12" md="3" >
                <h5>Tipos de documento</h5>
              </v-col>
              <v-col xs="12" sm="12" md="5" >
                <v-text-field
                  class="pt-0"
                  v-model="documentIdTypeSearch"
                  append-icon="mdi-magnify"
                  label="Search"
                  hide-details
                ></v-text-field>    
              </v-col>
              <v-col xs="12" sm="12" md="4" class="d-flex justify-center">
                <v-btn
                  dense
                  small
                  color="primary"
                  @click="openCreateForm(boards.document_id_type)"
                >
                  Crear tipo
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text class="pa-0 ma-0">
            <v-data-table          
              dense
              :headers="headers.document_id_type"
              :items="document_id_type_records"
              :items-per-page="5"
              multi-sort
              item-key="name"
              class=" rounded-xl"
              :documentIdTypeSearch="documentIdTypeSearch"
              :loading="loadingDocumentIdTypeTable"
              loading-text="Loading... Please wait"
            >

              <template v-slot:item.actions="{ item }">
                <v-icon color="warning" small class="mr-2" @click="editItem(item, boards.document_id_type)">
                  mdi-pencil
                </v-icon>
                <v-icon color="primary-red" small @click="openDelete(item, boards.document_id_type)">
                  mdi-delete
                </v-icon>
              </template>
            
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col sm="12" md="6">
        <v-card class="rounded-xl">
          <v-card-title class="py-2">
            <v-row align="center" justify="space-around">
              <v-col xs="12" sm="12" md="3" >
                <h5>VIP Statues</h5>
              </v-col>
              <v-col xs="12" sm="12" md="5" >
                <v-text-field
                  class="pt-0"
                  v-model="vipStatusSearch"
                  append-icon="mdi-magnify"
                  label="Search"
                  hide-details
                ></v-text-field>    
              </v-col>
              <v-col xs="12" sm="12" md="4" class="d-flex justify-center">
                <v-btn
                  dense
                  small
                  color="primary"
                  @click="openCreateForm(boards.vip_status)"
                >
                  Crear Status
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text class="pa-0 ma-0">
            <v-data-table          
              dense
              :headers="headers.vip_status"
              :items="vip_status_records"
              :items-per-page="5"
              multi-sort
              item-key="name"
              class=" rounded-xl"
              :vipStatusSearch="vipStatusSearch"
              :loading="loadingFamilyTable"
              loading-text="Loading... Please wait"
            >

              <template v-slot:item.actions="{ item }">
                <v-icon color="warning" small class="mr-2" @click="editItem(item, boards.vip_status)">
                  mdi-pencil
                </v-icon>
                <v-icon color="primary-red" small @click="openDelete(item, boards.vip_status)">
                  mdi-delete
                </v-icon>
              </template>
            
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row align="start">
      <v-col sm="12" md="6">
        <v-card class="rounded-xl">
          <v-card-title class="py-2">
            <v-row align="center" justify="space-around">
              <v-col xs="12" sm="12" md="3" >
                <h5>Clientes Frecuentes</h5>
              </v-col>
              <v-col xs="12" sm="12" md="5" >
                <v-text-field
                  class="pt-0"
                  v-model="repeatGuessSearch"
                  append-icon="mdi-magnify"
                  label="Search"
                  hide-details
                ></v-text-field>    
              </v-col>
              <v-col xs="12" sm="12" md="4" class="d-flex justify-center">
                <v-btn
                  dense
                  small
                  color="primary"
                  @click="openCreateForm(boards.repeat_guess)"
                >
                  Crear Cliente
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text class="pa-0 ma-0">
            <v-data-table          
              dense
              :headers="headers.repeat_guess"
              :items="repeat_guess_records"
              :items-per-page="5"
              multi-sort
              item-key="name"
              class=" rounded-xl"
              :repeatGuessSearch="repeatGuessSearch"
              :loading="loadingSubfamilyTable"
              loading-text="Loading... Please wait"
            >

              <template v-slot:item.actions="{ item }">
                <v-icon color="warning" small class="mr-2" @click="editItem(item, boards.repeat_guess)">
                  mdi-pencil
                </v-icon>
                <v-icon color="primary-red" small @click="openDelete(item, boards.repeat_guess)">
                  mdi-delete
                </v-icon>
              </template>
            
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Document id type Form -->
    <v-row justify="center" >
      <v-form ref="createDocumentIdTypeForm" v-model="valid" lazy-validation >
        <v-dialog  v-model="dialogDocumentIdType" persistent max-width="600px" >
          <v-card :loading="isLoading" :disabled="isLoading" class="rounded-xl" >
            <v-card-title>
              <span class="text-h6">{{ btnEdithVisible ? `Edicion Tipo Documento ${createDocumentIdTypeForm.name}` : `Crear Tipo Documento` }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-2">
              <v-container>
                <v-row dense>
                  <v-col xs="12" md="4">
                    <v-text-field
                      dense
                      label="Nombre"
                      v-model="createDocumentIdTypeForm.name"
                      :rules="createFormRules"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col xs="12" md="4">
                    <v-text-field
                      dense
                      label="Código"
                      v-model="createDocumentIdTypeForm.code"
                      :rules="createFormRules"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn 
                :disable="isLoading"
                color="blue darken-1" text @click="closeForm(boards.document_id_type)">
                Cerrar
              </v-btn>
              <v-btn
                :loading="isLoading"
                v-if="!btnEdithVisible"
                color="blue darken-1"
                text
                @click="summitForm('POST', boards.document_id_type)"
              >
                Guardar y cerrar
              </v-btn>
              <v-btn
                :loading="isLoading"
                v-if="btnEdithVisible"
                color="blue darken-1"
                text
                @click="summitForm('PUT', boards.document_id_type)"
              >
                Guardar Edicion
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
    </v-row>

    <!-- VIP Status Form -->
    <v-row justify="center" >
      <v-form ref="createVipStatusForm" v-model="valid" lazy-validation >
        <v-dialog  v-model="dialogVipStatus" persistent max-width="600px" >
          <v-card :loading="isLoading" :disabled="isLoading" class="rounded-xl" >
            <v-card-title>
              <span class="text-h6">{{ btnEdithVisible ? `Edicion VIP Status ${createVipStatusForm.name}` : `Crear VIP Status` }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-2">
              <v-container>
                <v-row dense>
                  <v-col xs="12" md="4">
                    <v-text-field
                      dense
                      label="Nombre"
                      v-model="createVipStatusForm.name"
                      :rules="createFormRules"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col xs="12" md="4">
                    <v-text-field
                      dense
                      label="Código"
                      v-model="createVipStatusForm.code"
                      :rules="createFormRules"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn 
                :disable="isLoading"
                color="blue darken-1" text @click="closeForm(boards.vip_status)">
                Cerrar
              </v-btn>
              <v-btn
                :loading="isLoading"
                v-if="!btnEdithVisible"
                color="blue darken-1"
                text
                @click="summitForm('POST', boards.vip_status)"
              >
                Guardar y cerrar
              </v-btn>
              <v-btn
                :loading="isLoading"
                v-if="btnEdithVisible"
                color="blue darken-1"
                text
                @click="summitForm('PUT', boards.vip_status)"
              >
                Guardar Edicion
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
    </v-row>

    <!-- Repeat Guess Form -->
    <v-row justify="center" >
      <v-form ref="createRepeatGuessForm" v-model="valid" lazy-validation >
        <v-dialog  v-model="dialogRepeatGuess" persistent max-width="600px" >
          <v-card :loading="isLoading" :disabled="isLoading" class="rounded-xl" >
            <v-card-title>
              <span class="text-h6">{{ btnEdithVisible ? `Edicion Cliente Frecuente ${createRepeatGuessForm.name}` : `Crear Cliente Frecuente` }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-2">
              <v-container>
                <v-row dense>
                  <v-col xs="12" md="4">
                    <v-text-field
                      dense
                      label="Nombre"
                      v-model="createRepeatGuessForm.name"
                      :rules="createFormRules"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col xs="12" md="4">
                    <v-text-field
                      dense
                      label="Código"
                      v-model="createRepeatGuessForm.code"
                      :rules="createFormRules"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn 
                :disable="isLoading"
                color="blue darken-1" text @click="closeForm(boards.repeat_guess)">
                Cerrar
              </v-btn>
              <v-btn
                :loading="isLoading"
                v-if="!btnEdithVisible"
                color="blue darken-1"
                text
                @click="summitForm('POST', boards.repeat_guess)"
              >
                Guardar y cerrar
              </v-btn>
              <v-btn
                :loading="isLoading"
                v-if="btnEdithVisible"
                color="blue darken-1"
                text
                @click="summitForm('PUT', boards.repeat_guess)"
              >
                Guardar Edicion
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
    </v-row>

    <!-- DIALOG DELETE DOCUMENT ID TYPE -->
    <v-dialog v-model="dialogDeleteDocumentIdType" max-width="500px">
      <v-card :loading="isLoading" :disabled="isLoading">
        <v-card-title class="text-h5">¿Está seguro de borrar este tipo de documento?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn small color="blue darken-1" text @click="closeDelete(boards.document_id_type)">Cancel</v-btn>
          <v-btn small color="blue darken-1" text @click="deleteItemConfirm(boards.document_id_type)">OK</v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG DELETE VIP STATUS -->
    <v-dialog v-model="dialogDeleteVipStatus" max-width="500px">
      <v-card :loading="isLoading" :disabled="isLoading">
        <v-card-title class="text-h5">¿Está seguro de borrar este status VIP?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn small color="blue darken-1" text @click="closeDelete(boards.vip_status)">Cancel</v-btn>
          <v-btn small color="blue darken-1" text @click="deleteItemConfirm(boards.vip_status)">OK</v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG DELETE REPEAT GUESS -->
    <v-dialog v-model="dialogDeleteRepeatGuess" max-width="500px">
      <v-card :loading="isLoading" :disabled="isLoading">
        <v-card-title class="text-h5">¿Está seguro de borrar este cliente frecuente?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn small color="blue darken-1" text @click="closeDelete(boards.repeat_guess)">Cancel</v-btn>
          <v-btn small color="blue darken-1" text @click="deleteItemConfirm(boards.repeat_guess)">OK</v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {axiosN506} from '../api/axiosBd'
export default {
  props:{
    headers: {
      type: Object,
      required: true
    },
    boards: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      //loadings
      isLoading: false,
      loadingDocumentIdTypeTable: false,
      loadingFamilyTable: false,
      loadingSubfamilyTable: false,
      //dialog delete
      dialogDeleteDocumentIdType: false,
      dialogDeleteVipStatus: false,
      dialogDeleteRepeatGuess: false,
      //search
      documentIdTypeSearch: '',
      vipStatusSearch: '',
      repeatGuessSearch: '',
      //records
      document_id_type_records: [],
      vip_status_records: [],
      repeat_guess_records: [],
      //form
      btnEdithVisible: false,
      valid: false,
      dialogDocumentIdType: false,
      dialogVipStatus: false,
      dialogRepeatGuess: false,
      createDocumentIdTypeForm: {},
      createVipStatusForm: {},
      createRepeatGuessForm: {},
      //form rules
      createFormRules: [
        v => !!v || 'El Campo es requerido',
      ],
      selectedRecord: null
    }
  },
  computed: {
  },
  methods: {
    async summitForm(method, board){
      this.isLoading = true
      let error = false
      let form = {}

      if (board == this.boards.document_id_type) {
        if (!this.$refs.createDocumentIdTypeForm.validate()) {
          error = true
        } else {
          form = this.createDocumentIdTypeForm
        }
      }

      if (board == this.boards.vip_status) {
        if (!this.$refs.createVipStatusForm.validate()) {
          error = true
        } else {
          form = this.createVipStatusForm
        }
      }

      if (board == this.boards.repeat_guess) {
        if (!this.$refs.createRepeatGuessForm.validate()) {
          error = true
        } else {
          form = this.createRepeatGuessForm
        }
      }

      if (!error) {
        await this.$store.dispatch('verifyToken')

        if (method == 'POST') {
          axiosN506(`${board}/add`, {
            method: "POST",
            data: form,
            headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
          })
          .then(() => {
            this.isLoading = false
            this.getData(board)
            this.closeForm(board)
          }).catch((err) => {
            alert(`error: ${err.response ? err.response : err}`)
            this.isLoading = false          
          })
        } else if (method == 'PUT') {
          axiosN506(`${board}/update`, {
            method: "PUT",
            data: { id: this.selectedRecord, ...form },
            headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
          })
          .then(() => {
            this.isLoading = false
            this.getData(board)
            this.closeForm(board)
          }).catch((err) => {
            alert(`error: ${err.response ? err.response : err}`)
            this.isLoading = false          
          })
        }
      } else {
        //construir componente o hacer prototype para mensajes de error standar en la aplicacion
        this.$alert('Favor llenar todos los campos requeridos', 'Title', {
          confirmButtonText: 'OK',
          callback: action => {
            this.isLoading = false
          }
        })
      }
    },
    editItem(item, board){
      // this.loa
      this.btnEdithVisible = true
      this.selectedRecord = item.id
      
      if (board == this.boards.document_id_type) {
        this.createDocumentIdTypeForm.name = item.name
        this.createDocumentIdTypeForm.code = item.code
        this.dialogDocumentIdType = true
      }

      if (board == this.boards.vip_status) {
        this.createVipStatusForm.name = item.name
        this.createVipStatusForm.code = item.code
        this.dialogVipStatus = true
      }

      if (board == this.boards.repeat_guess) {
        this.createRepeatGuessForm.name = item.name
        this.createRepeatGuessForm.code = item.code
        this.dialogRepeatGuess = true
      }
    },
    deleteItemConfirm(board) {
      this.isLoading = true

      axiosN506(`${board}/delete`, {
        method: "DELETE",
        data: { id: this.selectedRecord },
        headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
      })
      .then(() => {
        this.isLoading = false
        this.getData(board)
        this.closeDelete(board)
      }).catch((err) => {
        console.error(err)
        alert(`error: ${err.response ? err.response : err}`)
        this.isLoading = false          
      })
    },
    openDelete(item, board) {
      if (board == this.boards.document_id_type) this.dialogDeleteDocumentIdType = true
      if (board == this.boards.vip_status) this.dialogDeleteVipStatus = true
      if (board == this.boards.repeat_guess) this.dialogDeleteRepeatGuess = true

      this.selectedRecord = item.id
    },
    openCreateForm(board) {
      if (board == this.boards.document_id_type) this.dialogDocumentIdType = true
      if (board == this.boards.vip_status) this.dialogVipStatus = true
      if (board == this.boards.repeat_guess) this.dialogRepeatGuess = true
    },
    closeForm(board) {
      if (board == this.boards.document_id_type) {
        this.dialogDocumentIdType = false
        this.$refs.createDocumentIdTypeForm.resetValidation()
        this.createDocumentIdTypeForm = {}
      }

      if (board == this.boards.vip_status) {
        this.dialogVipStatus = false
        this.$refs.createVipStatusForm.resetValidation()
        this.createVipStatusForm = {}
      }

      if (board == this.boards.repeat_guess) {
        this.dialogRepeatGuess = false
        this.$refs.createRepeatGuessForm.resetValidation()
        this.createRepeatGuessForm = {}
      }

      this.btnEdithVisible = false
      this.isLoading = false
      this.selectedRecord = null
    },
    closeDelete(board) {
      this.selectedRecord = null

      if (board == this.boards.document_id_type) this.dialogDeleteDocumentIdType = false
      if (board == this.boards.vip_status) this.dialogDeleteVipStatus = false
      if (board == this.boards.repeat_guess) this.dialogDeleteRepeatGuess = false
    },
    getData(board){
      this.loadingTable = true
      this.$store.dispatch('verifyToken')
      axiosN506({
        method: 'GET',
        url: `${board}/list`,
        headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
      })
      .then((result) => {
        if (board == this.boards.document_id_type) this.loadingDocumentIdTypeTable = false
        if (board == this.boards.vip_status) this.loadingFamilyTable = false
        if (board == this.boards.repeat_guess) this.loadingSubfamilyTable = false
        
        if (board == this.boards.document_id_type) this.document_id_type_records = result.data.data
        if (board == this.boards.vip_status) this.vip_status_records = result.data.data
        if (board == this.boards.repeat_guess) this.repeat_guess_records = result.data.data
      }).catch((err) => {
        console.error(err)
      });
    },
    convertToMoney(x) {
      return x
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
  },
  created() {
    this.getData(this.boards.document_id_type)
    this.getData(this.boards.vip_status)
    this.getData(this.boards.repeat_guess)
  }
}
</script>