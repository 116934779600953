<template>
  <div>
    <v-row align="start">
      <v-col sm="12" md="7">
        <v-card class="rounded-xl">
          <v-card-title class="py-2">
            <v-row align="center" justify="space-around">
              <v-col xs="12" sm="12" md="3" >
                <h5>Impuestos</h5>
              </v-col>
              <v-col xs="12" sm="12" md="5" >
                <v-text-field
                  class="pt-0"
                  v-model="taxSearch"
                  append-icon="mdi-magnify"
                  label="Search"
                  hide-details
                ></v-text-field>    
              </v-col>
              <v-col xs="12" sm="12" md="4" class="d-flex justify-center">
                <v-btn
                  dense
                  small
                  color="primary"
                  @click="openCreateForm(boards.tax)"
                >
                  Crear Impuesto
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text class="pa-0 ma-0">
            <v-data-table          
              dense
              :headers="headers.tax"
              :items="tax_records"
              :items-per-page="5"
              multi-sort
              item-key="name"
              class=" rounded-xl"
              :taxSearch="taxSearch"
              :loading="loadingTaxTable"
              loading-text="Loading... Please wait"
            >

              <template v-slot:item.actions="{ item }">
                <v-icon color="warning" small class="mr-2" @click="editItem(item, boards.tax)">
                  mdi-pencil
                </v-icon>
                <v-icon color="primary-red" small @click="openDelete(item, boards.tax)">
                  mdi-delete
                </v-icon>
              </template>
            
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col sm="12" md="5">
        <v-card class="rounded-xl">
          <v-card-title class="py-2">
            <v-row align="center" justify="space-around">
              <v-col xs="12" sm="12" md="3" >
                <h5>Familia</h5>
              </v-col>
              <v-col xs="12" sm="12" md="5" >
                <v-text-field
                  class="pt-0"
                  v-model="familySearch"
                  append-icon="mdi-magnify"
                  label="Search"
                  hide-details
                ></v-text-field>    
              </v-col>
              <v-col xs="12" sm="12" md="4" class="d-flex justify-center">
                <v-btn
                  dense
                  small
                  color="primary"
                  @click="openCreateForm(boards.family)"
                >
                  Crear Familia
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text class="pa-0 ma-0">
            <v-data-table          
              dense
              :headers="headers.family"
              :items="family_records"
              :items-per-page="5"
              multi-sort
              item-key="name"
              class=" rounded-xl"
              :familySearch="familySearch"
              :loading="loadingFamilyTable"
              loading-text="Loading... Please wait"
            >

              <template v-slot:item.actions="{ item }">
                <v-icon color="warning" small class="mr-2" @click="editItem(item, boards.family)">
                  mdi-pencil
                </v-icon>
                <v-icon color="primary-red" small @click="openDelete(item, boards.family)">
                  mdi-delete
                </v-icon>
              </template>
            
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row align="start">
      <v-col sm="12" md="6">
        <v-card class="rounded-xl">
          <v-card-title class="py-2">
            <v-row align="center" justify="space-around">
              <v-col xs="12" sm="12" md="3" >
                <h5>Subfamilia</h5>
              </v-col>
              <v-col xs="12" sm="12" md="5" >
                <v-text-field
                  class="pt-0"
                  v-model="subfamilySearch"
                  append-icon="mdi-magnify"
                  label="Search"
                  hide-details
                ></v-text-field>    
              </v-col>
              <v-col xs="12" sm="12" md="4" class="d-flex justify-center">
                <v-btn
                  dense
                  small
                  color="primary"
                  @click="openCreateForm(boards.subfamily)"
                >
                  Crear Subfamilia
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text class="pa-0 ma-0">
            <v-data-table          
              dense
              :headers="headers.subfamily"
              :items="subfamily_records"
              :items-per-page="5"
              multi-sort
              item-key="name"
              class=" rounded-xl"
              :subfamilySearch="subfamilySearch"
              :loading="loadingSubfamilyTable"
              loading-text="Loading... Please wait"
            >

              <template v-slot:item.actions="{ item }">
                <v-icon color="warning" small class="mr-2" @click="editItem(item, boards.subfamily)">
                  mdi-pencil
                </v-icon>
                <v-icon color="primary-red" small @click="openDelete(item, boards.subfamily)">
                  mdi-delete
                </v-icon>
              </template>
            
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col sm="12" md="6">
        <v-card class="rounded-xl">
          <v-card-title class="py-2">
            <v-row align="center" justify="space-around">
              <v-col xs="12" sm="12" md="3" >
                <h5>Descuento</h5>
              </v-col>
              <v-col xs="12" sm="12" md="5" >
                <v-text-field
                  class="pt-0"
                  v-model="discountSearch"
                  append-icon="mdi-magnify"
                  label="Search"
                  hide-details
                ></v-text-field>    
              </v-col>
              <v-col xs="12" sm="12" md="4" class="d-flex justify-center">
                <v-btn
                  dense
                  small
                  color="primary"
                  @click="openCreateForm(boards.discount)"
                >
                  Crear Descuento
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text class="pa-0 ma-0">
            <v-data-table          
              dense
              :headers="headers.discount"
              :items="discount_records"
              :items-per-page="5"
              multi-sort
              item-key="name"
              class=" rounded-xl"
              :discountSearch="discountSearch"
              :loading="loadingDiscountTable"
              loading-text="Loading... Please wait"
            >

              <template v-slot:item.actions="{ item }">
                <v-icon color="warning" small class="mr-2" @click="editItem(item, boards.discount)">
                  mdi-pencil
                </v-icon>
                <v-icon color="primary-red" small @click="openDelete(item, boards.discount)">
                  mdi-delete
                </v-icon>
              </template>
            
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Tax Form -->
    <v-row justify="center" >
      <v-form ref="createTaxForm" v-model="valid" lazy-validation >
        <v-dialog  v-model="dialogTax" persistent max-width="600px" >
          <v-card :loading="isLoading" :disabled="isLoading" class="rounded-xl" >
            <v-card-title>
              <span class="text-h6">{{ btnEdithVisible ? `Edicion Impuesto ${createTaxForm.name}` : `Crear Impuesto` }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-2">
              <v-container>
                <v-row dense>
                  <v-col xs="12" md="4">
                    <v-text-field
                      dense
                      label="Nombre"
                      v-model="createTaxForm.name"
                      :rules="createFormRules"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col xs="12" md="4">
                    <v-text-field
                      dense
                      label="Rate"
                      v-model="createTaxForm.rate"
                      :rules="createFormRules"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn 
                :disable="isLoading"
                color="blue darken-1" text @click="closeForm(boards.tax)">
                Cerrar
              </v-btn>
              <v-btn
                :loading="isLoading"
                v-if="!btnEdithVisible"
                color="blue darken-1"
                text
                @click="summitForm('POST', boards.tax)"
              >
                Guardar y cerrar
              </v-btn>
              <v-btn
                :loading="isLoading"
                v-if="btnEdithVisible"
                color="blue darken-1"
                text
                @click="summitForm('PUT', boards.tax)"
              >
                Guardar Edicion
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
    </v-row>

    <!-- Family Form -->
    <v-row justify="center" >
      <v-form ref="createFamilyForm" v-model="valid" lazy-validation >
        <v-dialog  v-model="dialogFamily" persistent max-width="600px" >
          <v-card :loading="isLoading" :disabled="isLoading" class="rounded-xl" >
            <v-card-title>
              <span class="text-h6">{{ btnEdithVisible ? `Edicion Familia ${createFamilyForm.name}` : `Crear Familia` }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-2">
              <v-container>
                <v-row dense>
                  <v-col xs="12" md="4">
                    <v-text-field
                      dense
                      label="Nombre"
                      v-model="createFamilyForm.name"
                      :rules="createFormRules"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn 
                :disable="isLoading"
                color="blue darken-1" text @click="closeForm(boards.family)">
                Cerrar
              </v-btn>
              <v-btn
                :loading="isLoading"
                v-if="!btnEdithVisible"
                color="blue darken-1"
                text
                @click="summitForm('POST', boards.family)"
              >
                Guardar y cerrar
              </v-btn>
              <v-btn
                :loading="isLoading"
                v-if="btnEdithVisible"
                color="blue darken-1"
                text
                @click="summitForm('PUT', boards.family)"
              >
                Guardar Edicion
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
    </v-row>

    <!-- Subfamily Form -->
    <v-row justify="center" >
      <v-form ref="createSubfamilyForm" v-model="valid" lazy-validation >
        <v-dialog  v-model="dialogSubfamily" persistent max-width="600px" >
          <v-card :loading="isLoading" :disabled="isLoading" class="rounded-xl" >
            <v-card-title>
              <span class="text-h6">{{ btnEdithVisible ? `Edicion Subfamilia ${createSubfamilyForm.name}` : `Crear Subfamilia` }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-2">
              <v-container>
                <v-row dense>
                  <v-col xs="12" md="4">
                    <v-text-field
                      dense
                      label="Nombre"
                      v-model="createSubfamilyForm.name"
                      :rules="createFormRules"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col xs="12" md="4">
                    <v-autocomplete
                      class="text-caption"
                      v-model="createSubfamilyForm.family"
                      :rules="createFormRules"
                      item-value="id"
                      :items="family_records"
                      item-text="name"
                      dense
                      label="Familia"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn 
                :disable="isLoading"
                color="blue darken-1" text @click="closeForm(boards.subfamily)">
                Cerrar
              </v-btn>
              <v-btn
                :loading="isLoading"
                v-if="!btnEdithVisible"
                color="blue darken-1"
                text
                @click="summitForm('POST', boards.subfamily)"
              >
                Guardar y cerrar
              </v-btn>
              <v-btn
                :loading="isLoading"
                v-if="btnEdithVisible"
                color="blue darken-1"
                text
                @click="summitForm('PUT', boards.subfamily)"
              >
                Guardar Edicion
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
    </v-row>

    <!-- Discount Form -->
    <v-row justify="center" >
      <v-form ref="createDiscountForm" v-model="valid" lazy-validation >
        <v-dialog  v-model="dialogDiscount" persistent max-width="600px" >
          <v-card :loading="isLoading" :disabled="isLoading" class="rounded-xl" >
            <v-card-title>
              <span class="text-h6">{{ btnEdithVisible ? `Edicion Descuento ${createDiscountForm.name}` : `Crear Descuento` }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-2">
              <v-container>
                <v-row dense>
                  <v-col xs="12" md="4">
                    <v-text-field
                      dense
                      label="Nombre"
                      v-model="createDiscountForm.name"
                      :rules="createFormRules"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col xs="12" md="4">
                    <v-text-field
                      dense
                      label="Valor"
                      v-model="createDiscountForm.value"
                      :rules="createFormRules"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn 
                :disable="isLoading"
                color="blue darken-1" text @click="closeForm(boards.discount)">
                Cerrar
              </v-btn>
              <v-btn
                :loading="isLoading"
                v-if="!btnEdithVisible"
                color="blue darken-1"
                text
                @click="summitForm('POST', boards.discount)"
              >
                Guardar y cerrar
              </v-btn>
              <v-btn
                :loading="isLoading"
                v-if="btnEdithVisible"
                color="blue darken-1"
                text
                @click="summitForm('PUT', boards.discount)"
              >
                Guardar Edicion
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
    </v-row>

    <!-- DIALOG DELETE TAX -->
    <v-dialog v-model="dialogDeleteTax" max-width="500px">
      <v-card :loading="isLoading" :disabled="isLoading">
        <v-card-title class="text-h5">¿Está seguro de borrar este impuesto?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn small color="blue darken-1" text @click="closeDelete(boards.tax)">Cancel</v-btn>
          <v-btn small color="blue darken-1" text @click="deleteItemConfirm(boards.tax)">OK</v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG DELETE FAMILY -->
    <v-dialog v-model="dialogDeleteFamily" max-width="500px">
      <v-card :loading="isLoading" :disabled="isLoading">
        <v-card-title class="text-h5">¿Está seguro de borrar esta familia?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn small color="blue darken-1" text @click="closeDelete(boards.family)">Cancel</v-btn>
          <v-btn small color="blue darken-1" text @click="deleteItemConfirm(boards.family)">OK</v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG DELETE SUBFAMILY -->
    <v-dialog v-model="dialogDeleteSubfamily" max-width="500px">
      <v-card :loading="isLoading" :disabled="isLoading">
        <v-card-title class="text-h5">¿Está seguro de borrar esta subfamilia?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn small color="blue darken-1" text @click="closeDelete(boards.subfamily)">Cancel</v-btn>
          <v-btn small color="blue darken-1" text @click="deleteItemConfirm(boards.subfamily)">OK</v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG DELETE DISCOUNT -->
    <v-dialog v-model="dialogDeleteDiscount" max-width="500px">
      <v-card :loading="isLoading" :disabled="isLoading">
        <v-card-title class="text-h5">¿Está seguro de borrar esta subfamilia?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn small color="blue darken-1" text @click="closeDelete(boards.discount)">Cancel</v-btn>
          <v-btn small color="blue darken-1" text @click="deleteItemConfirm(boards.discount)">OK</v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {axiosN506} from '../api/axiosBd'
export default {
  props:{
    headers: {
      type: Object,
      required: true
    },
    boards: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      //loadings
      isLoading: false,
      loadingTaxTable: false,
      loadingFamilyTable: false,
      loadingSubfamilyTable: false,
      loadingDiscountTable: false,
      //dialog delete
      dialogDeleteTax: false,
      dialogDeleteFamily: false,
      dialogDeleteSubfamily: false,
      dialogDeleteDiscount: false,
      //search
      taxSearch: '',
      familySearch: '',
      subfamilySearch: '',
      discountSearch: '',
      //records
      tax_records: [],
      family_records: [],
      subfamily_records: [],
      discount_records: [],
      //form
      btnEdithVisible: false,
      valid: false,
      dialogTax: false,
      dialogFamily: false,
      dialogSubfamily: false,
      dialogDiscount: false,
      createTaxForm: {},
      createFamilyForm: {},
      createSubfamilyForm: {},
      createDiscountForm: {},
      //form rules
      createFormRules: [
        v => !!v || 'El Campo es requerido',
      ],
      selectedRecord: null
    }
  },
  computed: {
  },
  methods: {
    async summitForm(method, board){
      this.isLoading = true
      let error = false
      let form = {}

      if (board == this.boards.tax) {
        if (!this.$refs.createTaxForm.validate()) {
          error = true
        } else {
          form = this.createTaxForm
        }
      }

      if (board == this.boards.family) {
        if (!this.$refs.createFamilyForm.validate()) {
          error = true
        } else {
          form = this.createFamilyForm
        }
      }

      if (board == this.boards.subfamily) {
        if (!this.$refs.createSubfamilyForm.validate()) {
          error = true
        } else {
          form = this.createSubfamilyForm
        }
      }

      if (board == this.boards.discount) {
        if (!this.$refs.createDiscountForm.validate()) {
          error = true
        } else {
          form = this.createDiscountForm
        }
      }

      if (!error) {
        await this.$store.dispatch('verifyToken')

        if (method == 'POST') {
          axiosN506(`${board}/add`, {
            method: "POST",
            data: form,
            headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
          })
          .then(() => {
            this.isLoading = false
            this.getData(board)
            this.closeForm(board)
          }).catch((err) => {
            alert(`error: ${err.response ? err.response : err}`)
            this.isLoading = false          
          })
        } else if (method == 'PUT') {
          axiosN506(`${board}/update`, {
            method: "PUT",
            data: { id: this.selectedRecord, ...form },
            headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
          })
          .then(() => {
            this.isLoading = false
            this.getData(board)
            this.closeForm(board)
          }).catch((err) => {
            alert(`error: ${err.response ? err.response : err}`)
            this.isLoading = false          
          })
        }
      } else {
        //construir componente o hacer prototype para mensajes de error standar en la aplicacion
        this.$alert('Favor llenar todos los campos requeridos', 'Title', {
          confirmButtonText: 'OK',
          callback: action => {
            this.isLoading = false
          }
        })
      }
    },
    editItem(item, board){
      // this.loa
      this.btnEdithVisible = true
      this.selectedRecord = item.id
      
      if (board == this.boards.tax) {
        this.createTaxForm.name = item.name
        this.createTaxForm.rate = item.rate
        this.dialogTax = true
      }

      if (board == this.boards.family) {
        this.createFamilyForm.name = item.name
        this.dialogFamily = true
      }

      if (board == this.boards.subfamily) {
        this.createSubfamilyForm.name = item.name
        this.createSubfamilyForm.family = item.family
        this.dialogSubfamily = true
      }

      if (board == this.boards.discount) {
        this.createDiscountForm.name = item.name
        this.createDiscountForm.value = item.value
        this.dialogDiscount = true
      }
    },
    deleteItemConfirm(board) {
      this.isLoading = true

      axiosN506(`${board}/delete`, {
        method: "DELETE",
        data: { id: this.selectedRecord },
        headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
      })
      .then(() => {
        this.isLoading = false
        this.getData(board)
        this.closeDelete(board)
      }).catch((err) => {
        console.error(err)
        alert(`error: ${err.response ? err.response : err}`)
        this.isLoading = false          
      })
    },
    openDelete(item, board) {
      if (board == this.boards.tax) this.dialogDeleteTax = true
      if (board == this.boards.family) this.dialogDeleteFamily = true
      if (board == this.boards.subfamily) this.dialogDeleteSubfamily = true
      if (board == this.boards.discount) this.dialogDeleteDiscount = true

      this.selectedRecord = item.id
    },
    openCreateForm(board) {
      if (board == this.boards.tax) this.dialogTax = true
      if (board == this.boards.family) this.dialogFamily = true
      if (board == this.boards.subfamily) this.dialogSubfamily = true
      if (board == this.boards.discount) this.dialogDiscount = true
    },
    closeForm(board) {
      if (board == this.boards.tax) {
        this.dialogTax = false
        this.$refs.createTaxForm.resetValidation()
        this.createTaxForm = {}
      }

      if (board == this.boards.family) {
        this.dialogFamily = false
        this.$refs.createFamilyForm.resetValidation()
        this.createFamilyForm = {}
      }

      if (board == this.boards.subfamily) {
        this.dialogSubfamily = false
        this.$refs.createSubfamilyForm.resetValidation()
        this.createSubfamilyForm = {}
      }

      if (board == this.boards.discount) {
        this.dialogDiscount = false
        this.$refs.createDiscountForm.resetValidation()
        this.createDiscountForm = {}
      }

      this.btnEdithVisible = false
      this.isLoading = false
      this.selectedRecord = null
    },
    closeDelete(board) {
      this.selectedRecord = null

      if (board == this.boards.tax) this.dialogDeleteTax = false
      if (board == this.boards.family) this.dialogDeleteFamily = false
      if (board == this.boards.subfamily) this.dialogDeleteSubfamily = false
      if (board == this.boards.discount) this.dialogDeleteDiscount = false
    },
    getData(board){
      this.loadingTable = true
      this.$store.dispatch('verifyToken')
      axiosN506({
        method: 'GET',
        url: `${board}/list`,
        headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
      })
      .then((result) => {
        if (board == this.boards.tax) this.loadingTaxTable = false
        if (board == this.boards.family) this.loadingFamilyTable = false
        if (board == this.boards.subfamily) this.loadingSubfamilyTable = false
        if (board == this.boards.discount) this.loadingDiscountTable = false
        
        if (board == this.boards.tax) this.tax_records = result.data.data
        if (board == this.boards.family) this.family_records = result.data.data
        if (board == this.boards.subfamily) this.subfamily_records = result.data.data
        if (board == this.boards.discount) this.discount_records = result.data.data
      }).catch((err) => {
        console.error(err)
      });
    },
    convertToMoney(x) {
      return x
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
  },
  created() {
    this.getData(this.boards.tax)
    this.getData(this.boards.family)
    this.getData(this.boards.subfamily)
    this.getData(this.boards.discount)
  }
}
</script>