<template>
  <div>
    <v-row align="start">
      <v-col sm="12" md="6">
        <v-card class="rounded-xl">
          <v-card-title class="py-2">
            <v-row align="center" justify="space-around">
              <v-col xs="12" sm="12" md="3" >
                <h5>market</h5>
              </v-col>
              <v-col xs="12" sm="12" md="5" >
                <v-text-field
                  class="pt-0"
                  v-model="marketSearch"
                  append-icon="mdi-magnify"
                  label="Search"
                  hide-details
                ></v-text-field>    
              </v-col>
              <v-col xs="12" sm="12" md="4" class="d-flex justify-center">
                <v-btn
                  dense
                  small
                  color="primary"
                  @click="openCreateForm(boards.market)"
                >
                  Crear Market
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text class="pa-0 ma-0">
            <v-data-table          
              dense
              :headers="headers.market"
              :items="markets_records"
              :items-per-page="5"
              multi-sort
              item-key="name"
              class=" rounded-xl"
              :search="marketSearch"
              :loading="loadingMarketTable"
              loading-text="Loading... Please wait"
            >

              <template v-slot:item.actions="{ item }">
                <v-icon color="warning" small class="mr-2" @click="editItem(item, boards.market)">
                  mdi-pencil
                </v-icon>
                <v-icon color="primary-red" small @click="openDelete(item, boards.market)">
                  mdi-delete
                </v-icon>
              </template>
            
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col sm="12" md="6">
        <v-card class="rounded-xl">
          <v-card-title class="py-2">
            <v-row align="center" justify="space-around">
              <v-col xs="12" sm="12" md="3" >
                <h5>segment</h5>
              </v-col>
              <v-col xs="12" sm="12" md="5" >
                <v-text-field
                  class="pt-0"
                  v-model="segmentSearch"
                  append-icon="mdi-magnify"
                  label="Search"
                  hide-details
                ></v-text-field>    
              </v-col>
              <v-col xs="12" sm="12" md="4" class="d-flex justify-center">
                <v-btn
                  dense
                  small
                  color="primary"
                  @click="openCreateForm(boards.segment)"
                >
                  Crear Segmengt
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text class="pa-0 ma-0">
            <v-data-table          
              dense
              :headers="headers.segment"
              :items="segment_records"
              :items-per-page="5"
              multi-sort
              item-key="name"
              class=" rounded-xl"
              :search="segmentSearch"
              :loading="loadingSegmentTable"
              loading-text="Loading... Please wait"
            >

              <template v-slot:item.actions="{ item }">
                <v-icon color="warning" small class="mr-2" @click="editItem(item, boards.segment)">
                  mdi-pencil
                </v-icon>
                <v-icon color="primary-red" small @click="openDelete(item, boards.segment)">
                  mdi-delete
                </v-icon>
              </template>
            
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row align="start">
      <v-col sm="12" md="6">
        <v-card class="rounded-xl">
          <v-card-title class="py-2">
            <v-row align="center" justify="space-around">
              <v-col xs="12" sm="12" md="3" >
                <h5>Estado de la reservas</h5>
              </v-col>
              <v-col xs="12" sm="12" md="5" >
                <v-text-field
                  class="pt-0"
                  v-model="reservationStatusSearch"
                  append-icon="mdi-magnify"
                  label="Search"
                  hide-details
                ></v-text-field>    
              </v-col>
              <v-col xs="12" sm="12" md="4" class="d-flex justify-center">
                <v-btn
                  dense
                  small
                  color="primary"
                  @click="openCreateForm(boards.reservation_status)"
                >
                  Crear Estados
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text class="pa-0 ma-0">
            <v-data-table          
              dense
              :headers="headers.reservation_status"
              :items="reservationStatus_records"
              :items-per-page="5"
              multi-sort
              item-key="name"
              class=" rounded-xl"
              :search="reservationStatusSearch"
              :loading="loadingReservationStatusTable"
              loading-text="Loading... Please wait"
            >

              <template v-slot:item.actions="{ item }">
                <v-icon color="warning" small class="mr-2" @click="editItem(item, boards.reservation_status)">
                  mdi-pencil
                </v-icon>
                <v-icon color="primary-red" small @click="openDelete(item, boards.reservation_status)">
                  mdi-delete
                </v-icon>
              </template>
            
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col sm="12" md="6">
        <v-card class="rounded-xl">
          <v-card-title class="py-2">
            <v-row align="center" justify="space-around">
              <v-col xs="12" sm="12" md="3" >
                <h5>Métodos de pago</h5>
              </v-col>
              <v-col xs="12" sm="12" md="5" >
                <v-text-field
                  class="pt-0"
                  v-model="paymentMethodSearch"
                  append-icon="mdi-magnify"
                  label="Search"
                  hide-details
                ></v-text-field>    
              </v-col>
              <v-col xs="12" sm="12" md="4" class="d-flex justify-center">
                <v-btn
                  dense
                  small
                  color="primary"
                  @click="openCreateForm(boards.payment_method)"
                >
                  Crear Método
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text class="pa-0 ma-0">
            <v-data-table          
              dense
              :headers="headers.payment_method"
              :items="paymentMethod_records"
              :items-per-page="5"
              multi-sort
              item-key="name"
              class=" rounded-xl"
              :search="paymentMethodSearch"
              :loading="loadingPaymentMethodTable"
              loading-text="Loading... Please wait"
            >

              <template v-slot:item.actions="{ item }">
                <v-icon color="warning" small class="mr-2" @click="editItem(item, boards.payment_method)">
                  mdi-pencil
                </v-icon>
                <v-icon color="primary-red" small @click="openDelete(item, boards.payment_method)">
                  mdi-delete
                </v-icon>
              </template>
            
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Market Form -->
    <v-row justify="center" >
      <v-form ref="createMarketForm" v-model="valid" lazy-validation >
        <v-dialog  v-model="dialogMarket" persistent max-width="600px" >
          <v-card :loading="isLoading" :disabled="isLoading" class="rounded-xl" >
            <v-card-title>
              <span class="text-h6">{{ btnEdithVisible ? `Edicion Market ${createMarketForm.name}` : `Crear Market` }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-2">
              <v-container>
                <v-row dense>
                  <v-col xs="12" md="4">
                    <v-text-field
                      dense
                      label="Type"
                      v-model="createMarketForm.type"
                      :rules="createFormRules"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col xs="12" md="4">
                    <v-text-field
                      dense
                      label="Nombre"
                      v-model="createMarketForm.name"
                      :rules="createFormRules"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn 
                :disable="isLoading"
                color="blue darken-1" text @click="closeForm(boards.market)">
                Cerrar
              </v-btn>
              <v-btn
                :loading="isLoading"
                v-if="!btnEdithVisible"
                color="blue darken-1"
                text
                @click="summitForm('POST', boards.market)"
              >
                Guardar y cerrar
              </v-btn>
              <v-btn
                :loading="isLoading"
                v-if="btnEdithVisible"
                color="blue darken-1"
                text
                @click="summitForm('PUT', boards.market)"
              >
                Guardar Edicion
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
    </v-row>

    <!-- Segment Form -->
    <v-row justify="center" >
      <v-form ref="createSegmentForm" v-model="valid" lazy-validation >
        <v-dialog  v-model="dialogSegment" persistent max-width="600px" >
          <v-card :loading="isLoading" :disabled="isLoading" class="rounded-xl" >
            <v-card-title>
              <span class="text-h6">{{ btnEdithVisible ? `Edicion Segment ${createSegmentForm.name}` : `Crear Segment` }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-2">
              <v-container>
                <v-row dense>
                  <v-col xs="12" md="4">
                    <v-text-field
                      dense
                      label="Tipo"
                      v-model="createSegmentForm.type"
                      :rules="createFormRules"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col xs="12" md="4">
                    <v-text-field
                      dense
                      label="Nombre"
                      v-model="createSegmentForm.name"
                      :rules="createFormRules"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn 
                :disable="isLoading"
                color="blue darken-1" text @click="closeForm(boards.segment)">
                Cerrar
              </v-btn>
              <v-btn
                :loading="isLoading"
                v-if="!btnEdithVisible"
                color="blue darken-1"
                text
                @click="summitForm('POST', boards.segment)"
              >
                Guardar y cerrar
              </v-btn>
              <v-btn
                :loading="isLoading"
                v-if="btnEdithVisible"
                color="blue darken-1"
                text
                @click="summitForm('PUT', boards.segment)"
              >
                Guardar Edicion
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
    </v-row>

    <!-- Reservation Status Form -->
    <v-row justify="center" >
      <v-form ref="createReservationStatusForm" v-model="valid" lazy-validation >
        <v-dialog  v-model="dialogReservationStatus" persistent max-width="600px" >
          <v-card :loading="isLoading" :disabled="isLoading" class="rounded-xl" >
            <v-card-title>
              <span class="text-h6">{{ btnEdithVisible ? `Edicion Estado ${createReservationStatusForm.name}` : `Crear Estado` }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-2">
              <v-container>
                <v-row dense>
                  <v-col xs="12" md="4">
                     <v-text-field
                      dense
                      label="Codigo"
                      v-model="createReservationStatusForm.code"
                      :rules="createFormRules"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col xs="12" md="4">
                    <v-text-field
                      dense
                      label="Nombre"
                      v-model="createReservationStatusForm.name"
                      :rules="createFormRules"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn 
                :disable="isLoading"
                color="blue darken-1" text @click="closeForm(boards.reservation_status)">
                Cerrar
              </v-btn>
              <v-btn
                :loading="isLoading"
                v-if="!btnEdithVisible"
                color="blue darken-1"
                text
                @click="summitForm('POST', boards.reservation_status)"
              >
                Guardar y cerrar
              </v-btn>
              <v-btn
                :loading="isLoading"
                v-if="btnEdithVisible"
                color="blue darken-1"
                text
                @click="summitForm('PUT', boards.reservation_status)"
              >
                Guardar Edicion
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
    </v-row>

    <!-- Payment Method Form -->
    <v-row justify="center" >
      <v-form ref="createPaymentMethodForm" v-model="valid" lazy-validation >
        <v-dialog  v-model="dialogPaymentMethod" persistent max-width="600px" >
          <v-card :loading="isLoading" :disabled="isLoading" class="rounded-xl" >
            <v-card-title>
              <span class="text-h6">{{ btnEdithVisible ? `Edicion Método de Pago ${createPaymentMethodForm.name}` : `Crear Método de Pago` }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-2">
              <v-container>
                <v-row dense>
                  <v-col xs="12" md="4">
                    <v-text-field
                      dense
                      label="Nombre"
                      v-model="createPaymentMethodForm.name"
                      :rules="createFormRules"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col xs="12" md="4">
                     <v-text-field
                      dense
                      label="Codigo"
                      v-model="createPaymentMethodForm.code"
                      :rules="createFormRules"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn 
                :disable="isLoading"
                color="blue darken-1" text @click="closeForm(boards.payment_method)">
                Cerrar
              </v-btn>
              <v-btn
                :loading="isLoading"
                v-if="!btnEdithVisible"
                color="blue darken-1"
                text
                @click="summitForm('POST', boards.payment_method)"
              >
                Guardar y cerrar
              </v-btn>
              <v-btn
                :loading="isLoading"
                v-if="btnEdithVisible"
                color="blue darken-1"
                text
                @click="summitForm('PUT', boards.payment_method)"
              >
                Guardar Edicion
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
    </v-row>

    <!-- DIALOG DELETE MARKET -->
    <v-dialog v-model="dialogDeleteTax" max-width="500px">
      <v-card :loading="isLoading" :disabled="isLoading">
        <v-card-title class="text-h5">¿Está seguro de borrar este Market?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn small color="blue darken-1" text @click="closeDelete(boards.market)">Cancel</v-btn>
          <v-btn small color="blue darken-1" text @click="deleteItemConfirm(boards.market)">OK</v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG DELETE SEGMENT -->
    <v-dialog v-model="dialogDeleteFamily" max-width="500px">
      <v-card :loading="isLoading" :disabled="isLoading">
        <v-card-title class="text-h5">¿Está seguro de borrar este Segment?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn small color="blue darken-1" text @click="closeDelete(boards.segment)">Cancel</v-btn>
          <v-btn small color="blue darken-1" text @click="deleteItemConfirm(boards.segment)">OK</v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG DELETE ESTADOS -->
    <v-dialog v-model="dialogDeleteReservationStatus" max-width="500px">
      <v-card :loading="isLoading" :disabled="isLoading">
        <v-card-title class="text-h5">¿Está seguro de borrar esta subfamilia?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn small color="blue darken-1" text @click="closeDelete(boards.reservation_status)">Cancel</v-btn>
          <v-btn small color="blue darken-1" text @click="deleteItemConfirm(boards.reservation_status)">OK</v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG DELETE PAYMENT METHOD -->
    <v-dialog v-model="dialogDeletePaymentMethod" max-width="500px">
      <v-card :loading="isLoading" :disabled="isLoading">
        <v-card-title class="text-h5">¿Está seguro de borrar este método de pago?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn small color="blue darken-1" text @click="closeDelete(boards.payment_method)">Cancel</v-btn>
          <v-btn small color="blue darken-1" text @click="deleteItemConfirm(boards.payment_method)">OK</v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div> 
</template>

<script>
import {axiosN506} from '../api/axiosBd'
export default {
  props:{
    headers: {
      type: Object,
      required: true
    },
    boards: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      //loadings
      isLoading: false,
      loadingMarketTable: false,
      loadingSegmentTable: false,
      loadingReservationStatusTable: false,
      loadingPaymentMethodTable: false,
      //dialog delete
      dialogDeleteTax: false,
      dialogDeleteFamily: false,
      dialogDeleteReservationStatus: false,
      dialogDeletePaymentMethod: false,
      //search
      marketSearch: '',
      segmentSearch: '',
      reservationStatusSearch: '',
      paymentMethodSearch: '',
      //records
      markets_records: [],
      segment_records: [],
      reservationStatus_records: [],
      paymentMethod_records: [],
      //form
      btnEdithVisible: false,
      valid: false,
      dialogMarket: false,
      dialogSegment: false,
      dialogReservationStatus: false,
      dialogPaymentMethod: false,
      createMarketForm: {},
      createSegmentForm: {},
      createReservationStatusForm: {},
      createPaymentMethodForm: {},
      //form rules
      createFormRules: [
        v => !!v || 'El Campo es requerido',
      ],
      selectedRecord: null
    }
  },
  computed: {
  },
  methods: {
    async summitForm(method, board){
      this.isLoading = true
      let error = false
      let form = {}

      if (board == this.boards.market) {
        if (!this.$refs.createMarketForm.validate()) {
          error = true
        } else {
          form = this.createMarketForm
        }
      }

      if (board == this.boards.segment) {
        if (!this.$refs.createSegmentForm.validate()) {
          error = true
        } else {
          form = this.createSegmentForm
        }
      }

      if (board == this.boards.reservation_status) {
        if (!this.$refs.createReservationStatusForm.validate()) {
          error = true
        } else {
          form = this.createReservationStatusForm
        }
      }

      if (board == this.boards.payment_method) {
        if (!this.$refs.createPaymentMethodForm.validate()) {
          error = true
        } else {
          form = this.createPaymentMethodForm
        }
      }

      if (!error) {
        await this.$store.dispatch('verifyToken')

        if (method == 'POST') {
          axiosN506(`${board}/add`, {
            method: "POST",
            data: form,
            headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
          })
          .then(() => {
            this.isLoading = false
            this.getData(board)
            this.closeForm(board)
          }).catch((err) => {
            alert(`error: ${err.response ? err.response : err}`)
            this.isLoading = false          
          })
        } else if (method == 'PUT') {
          axiosN506(`${board}/update`, {
            method: "PUT",
            data: { id: this.selectedRecord, ...form },
            headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
          })
          .then(() => {
            this.isLoading = false
            this.getData(board)
            this.closeForm(board)
          }).catch((err) => {
            alert(`error: ${err.response ? err.response : err}`)
            this.isLoading = false          
          })
        }
      } else {
        //construir componente o hacer prototype para mensajes de error standar en la aplicacion
        this.$alert('Favor llenar todos los campos requeridos', 'Title', {
          confirmButtonText: 'OK',
          callback: action => {
            this.isLoading = false
          }
        })
      }
      
      // this.isLoading = true
    },
    editItem(item, board){
      // this.loa
      this.btnEdithVisible = true
      this.selectedRecord = item.id
      
      if (board == this.boards.market) {
        this.createMarketForm.name = item.name
        this.createMarketForm.type = item.type
        this.dialogMarket = true
      }

      if (board == this.boards.segment) {
        this.createSegmentForm.name = item.name
        this.createSegmentForm.type = item.type
        this.dialogSegment = true
      }

      if (board == this.boards.reservation_status) {
        this.createReservationStatusForm.name = item.name
        this.createReservationStatusForm.code = item.code
        this.dialogReservationStatus = true
      }

      if (board == this.boards.payment_method) {
        this.createPaymentMethodForm.name = item.name
        this.createPaymentMethodForm.code = item.code
        this.dialogPaymentMethod = true
      }
    },
    deleteItemConfirm(board) {
      this.isLoading = true

      axiosN506(`${board}/delete`, {
        method: "DELETE",
        data: { id: this.selectedRecord },
        headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
      })
      .then(() => {
        this.isLoading = false
        this.getData(board)
        this.closeDelete(board)
      }).catch((err) => {
        console.error(err)
        alert(`error: ${err.response ? err.response : err}`)
        this.isLoading = false          
      })
    },
    openDelete(item, board) {
      if (board == this.boards.market) this.dialogDeleteTax = true
      if (board == this.boards.segment) this.dialogDeleteFamily = true
      if (board == this.boards.reservation_status) this.dialogDeleteReservationStatus = true
      if (board == this.boards.payment_method) this.dialogDeletePaymentMethod = true

      this.selectedRecord = item.id
    },
    openCreateForm(board) {
      if (board == this.boards.market) this.dialogMarket = true
      if (board == this.boards.segment) this.dialogSegment = true
      if (board == this.boards.reservation_status) this.dialogReservationStatus = true
      if (board == this.boards.payment_method) this.dialogPaymentMethod = true
    },
    closeForm(board) {
      if (board == this.boards.market) {
        this.dialogMarket = false
        this.$refs.createMarketForm.resetValidation()
        this.createMarketForm = {}
      }

      if (board == this.boards.segment) {
        this.dialogSegment = false
        this.$refs.createSegmentForm.resetValidation()
        this.createSegmentForm = {}
      }

      if (board == this.boards.reservation_status) {
        this.dialogReservationStatus = false
        this.$refs.createReservationStatusForm.resetValidation()
        this.createReservationStatusForm = {}
      }

      if (board == this.boards.payment_method) {
        this.dialogPaymentMethod = false
        this.$refs.createPaymentMethodForm.resetValidation()
        this.createPaymentMethodForm = {}
      }

      this.btnEdithVisible = false
      this.isLoading = false
      this.selectedRecord = null
    },
    closeDelete(board) {
      this.selectedRecord = null

      if (board == this.boards.market) this.dialogDeleteTax = false
      if (board == this.boards.segment) this.dialogDeleteFamily = false
      if (board == this.boards.reservation_status) this.dialogDeleteReservationStatus = false
      if (board == this.boards.payment_method) this.dialogDeletePaymentMethod = false
    },
    getData(board){
      this.loadingTable = true
      this.$store.dispatch('verifyToken')
      axiosN506({
        method: 'GET',
        url: `${board}/list`,
        headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
      })
      .then((result) => {
        if (board == this.boards.market) this.loadingMarketTable = false
        if (board == this.boards.segment) this.loadingSegmentTable = false
        if (board == this.boards.reservation_status) this.loadingReservationStatusTable = false
        if (board == this.boards.payment_method) this.loadingPaymentMethodTable = false
        
        if (board == this.boards.market) this.markets_records = result.data.data
        if (board == this.boards.segment) this.segment_records = result.data.data
        if (board == this.boards.reservation_status) this.reservationStatus_records = result.data.data
        if (board == this.boards.payment_method) this.paymentMethod_records = result.data.data
      }).catch((err) => {
        console.error(err)
      });
    },
    convertToMoney(x) {
      return x
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
  },
  created() {
    this.getData(this.boards.market)
    this.getData(this.boards.segment)
    this.getData(this.boards.reservation_status)
    this.getData(this.boards.payment_method)
  }
}
</script>