<template>
 <v-container fluid class="py-3 px-5">
   <v-row>
     <v-col cols="12" class="pb-1">
       <p class="ma-1">
         PARÁMETROS DE CLIENTES
       </p>
     </v-col>
   </v-row>
   <v-row>
     <v-col cols="12" class="pt-1">
       <CustomerParamsTable :headers="headers" :boards="boards">

       </CustomerParamsTable>
     </v-col>
   </v-row>
 </v-container>
</template>

<script>
import CustomerParamsTable from '@/components/CustomerParamsTable.vue' 
export default {
  name: 'CustomerParams',
  components: {
    CustomerParamsTable
  },
  data() {
    return {
      headers: {
        document_id_type: [
          {
            text: 'Nombre',
            align: 'start',
            value: 'name',
          },
          {
            text: 'Código',
            align: 'start',
            value: 'code',
          },
          {
            text: "Acciones",
            align: 'center', 
            value: "actions"
          }
        ],
        vip_status: [
          {
            text: 'Nombre',
            align: 'start',
            value: 'name',
          },
          {
            text: 'Código',
            align: 'start',
            value: 'code',
          },
          {
            text: "Acciones",
            align: 'center', 
            value: "actions"
          }
        ],
        repeat_guess: [
          {
            text: 'Nombre',
            align: 'start',
            value: 'name',
          },
          {
            text: 'Código',
            align: 'start',
            value: 'code',
          },
          {
            text: "Acciones",
            align: 'center', 
            value: "actions"
          }
        ]
      },
      boards: {
        document_id_type: 'document_id_type',
        vip_status: 'vip_status',
        repeat_guess: 'repeat_guess'
      }
    }
  },
}
</script>

<style>

</style>