<template>
 <v-container fluid class="py-3 px-5">
   <v-row>
     <v-col cols="12" class="pb-1">
       <p class="ma-1">
         PARÁMETROS DE PRODUCTOS
       </p>
     </v-col>
   </v-row>
   <v-row>
     <v-col cols="12" class="pt-1">
       <ProductParamsTable :headers="headers" :boards="boards">

       </ProductParamsTable>
     </v-col>
   </v-row>
 </v-container>
</template>

<script>
import ProductParamsTable from '@/components/ProductParamsTable.vue' 
export default {
  name: 'ProductParams',
  components: {
    ProductParamsTable
  },
  data() {
    return {
      headers: {
        tax: [
          {
            text: 'Nombre',
            align: 'start',
            value: 'name',
          },
          {
            text: 'Rate',
            align: 'start',
            value: 'rate',
          },
          {
            text: "Acciones",
            align: 'center', 
            value: "actions"
          }
        ],
        family: [
          {
            text: 'Nombre',
            align: 'start',
            value: 'name',
          },
          {
            text: "Acciones",
            align: 'center', 
            value: "actions"
          }
        ],
        subfamily: [
          {
            text: 'Nombre',
            align: 'start',
            value: 'name',
          },
          {
            text: 'Familia',
            align: 'start',
            value: 'familyId.name',
          },
          {
            text: "Acciones",
            align: 'center', 
            value: "actions"
          }
        ],
        discount: [
          {
            text: 'Nombre',
            align: 'start',
            value: 'name',
          },
          {
            text: 'Valor',
            align: 'start',
            value: 'value',
          },
          {
            text: "Acciones",
            align: 'center', 
            value: "actions"
          }
        ]
      },
      boards: {
        tax: 'tax',
        family: 'family',
        subfamily: 'subfamily',
        discount: 'discount'
      }
    }
  },
}
</script>

<style>

</style>